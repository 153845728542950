import React from 'react';
import { Flex } from '../../components/Flex';
import { EmptyBasket } from '../Offer/Empty/EmptyBasket';
import { OfferCategorySkeleton } from './Categories/OfferCategorySkeleton';
import { useHandleBasketCategories } from '../../utils/hooks/basket/useHandleBasketCategories';
import { OfferBasketCategory } from './Categories/OfferCategory';
import { useMultiBaskets } from '../../provider/Basket/MultiBasketsProvider';
interface Props {
  basketId: number;
}
export const BasketCategories = ({ basketId }: Props): JSX.Element => {
  const { isFetching, baskets } = useMultiBaskets();
  const { groupedOffers, isGrouping } = useHandleBasketCategories({ basketId });

  return (
    <Flex
      direction="col"
      justify="center"
      className="gap-8 grid lg:grid-cols-2 xl:grid-cols-3"
    >
      {!isFetching &&
        baskets?.[basketId] &&
        baskets?.[basketId]?.offers_list &&
        baskets?.[basketId]?.offers_list?.length === 0 && <EmptyBasket />}
      {isFetching || isGrouping
        ? Array.from({ length: 4 }).map((_, index) => (
            <OfferCategorySkeleton key={index} />
          ))
        : !!groupedOffers &&
          Object.entries(groupedOffers)?.map(([category, offers]) => (
            <OfferBasketCategory
              key={category}
              basketId={Number(basketId)}
              category={category}
              offers={offers}
            />
          ))}
    </Flex>
  );
};
