import React from 'react';
import { Flex } from '../../../../components/Flex';
import { Button } from '../../../../components/Button';
import Icons from '../../../../../public/assets/images/icons';
import { Offer } from '../../../../interfaces/Offer';
import { ImageWithFallback } from '../../../../components/ImageWithFallback';
import { IMAGES_BASE_URL } from '../../../../utils/constants/envs';
import SampleProductImage from '../../../../../public/assets/images/sample/sampleProductImage.png';
import { SmallSlider } from '../../../../components/Slider';
interface Props {
  handleSelectedCategory?: () => void;
  offers?: Offer[];
  offersLength: number;
}
export const BasketCategoryButtons = ({
  handleSelectedCategory,
  offers,
  offersLength
}: Props): JSX.Element => {
  return (
    <Flex className="w-full gap-2 flex-col px-6">
      <p className="font-[600] text-neutral-pure1000">Produtos da categoria</p>
      <Flex className="w-full gap-4 flex-col">
        <SmallSlider options={{ dragFree: true }}>
          <Flex className="gap-2" align="center">
            {offers?.map((offer) => {
              return (
                <Flex
                  key={offer.id}
                  className="w-[86px] h-[88px] bg-neutral-pure200 py-6 px-5"
                  align="center"
                  justify="center"
                >
                  <ImageWithFallback
                    width={68}
                    height={68}
                    layout="fixed"
                    objectFit="contain"
                    src={
                      `${IMAGES_BASE_URL}${offer.commercial_product.base_product?.identifier_code}.jpg` ||
                      SampleProductImage
                    }
                    alt={
                      offer.commercial_product.base_product?.description ||
                      'Produto'
                    }
                    fallbackSrc={SampleProductImage}
                  />
                </Flex>
              );
            })}
            {offersLength > 10 && (
              <Flex
                className=" h-[88px] w-[86px] bg-neutral-pure200 py-6 px-2 text-center text-[18px] font-[600] text-neutral-pure700"
                align="center"
                justify="center"
              >
                <p>+ {offersLength - 10} </p>
              </Flex>
            )}
          </Flex>
        </SmallSlider>
        <Button
          style="ghost"
          onClick={() => {
            handleSelectedCategory?.();
          }}
          className="hover:bg-primary-pureOp10  bg-primary-pure10 text-primary-pure font-[600] normal-case"
        >
          <Flex align="center" className="gap-2 text-primary-pure">
            <Icons.BasketAdd className="w-[25px] mb-1" />
            Ver todos os produtos
          </Flex>
        </Button>
      </Flex>
    </Flex>
  );
};
