import { useEffect, useState } from 'react';
import { Offer } from '../../../interfaces/Offer';
import { CartItem } from '../../../interfaces/Cart';
import { useMultiBaskets } from '../../../provider/Basket/MultiBasketsProvider';
import { useModal } from '../useModal';
import { useQueryClient } from 'react-query';
import { useMutateBasket } from '../../../http/mutations/Basket/useMutateBasket';
import { groupByCategory } from '../../helpers/offer';
import { useHandleBasket } from './useHandleBasket';
import { removeOfferFromMultiBasket } from '../../../provider/Basket/action';
import { toast } from 'react-toastify';
interface Props {
  basketId: number;
}
export const useHandleBasketCategories = ({ basketId }: Props) => {
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [isGrouping, setIsGrouping] = useState(true);
  const [groupedOffers, setGroupedOffers] = useState<Record<string, Offer[]>>(
    {}
  );
  const [selectedOffer, setSelectedOffer] = useState<Offer | null>(
    Object.values(groupedOffers)?.[0]?.[0]
  );

  const [modalOffers, setModalOffers] = useState<Offer[]>([]);

  const [selectedProduct, setSelectedProduct] = useState<CartItem | null>(null);
  const handleSelectedOffer = (offer: Offer) => {
    setSelectedOffer(offer);
  };

  const handleSelectedCategory = (category: string) => {
    setSelectedCategory(category);
  };

  const handleSelectedProduct = (cartItem: CartItem) => {
    setSelectedProduct(cartItem);
  };

  const { state, dispatch } = useMultiBaskets();

  const { closeModal } = useModal({ modalId: 'deleteProduct' });
  const queryClient = useQueryClient();

  const { mutateAsync: mutateBasket } = useMutateBasket({});

  const { percentageSaved } = useHandleBasket({
    offers:
      !!state && !!state?.baskets && !!state?.baskets?.[basketId]
        ? state?.baskets?.[basketId]?.offers_list.filter((offer) =>
            offer.commercial_product.base_product?.sub_category?.category.description.includes(
              selectedCategory
            )
          )
        : [],
    basketId: Number(basketId)
  });

  const handleDeleteProduct = async () => {
    if (!selectedProduct) return;
    dispatch(
      removeOfferFromMultiBasket({
        basketId: Number(basketId),
        offerId: selectedProduct.id
      })
    );
    await mutateBasket({
      id: Number(basketId),
      offers_list: [
        ...state?.baskets[basketId].offers_list
          .filter((filteredOffer) => filteredOffer.id !== selectedProduct.id)
          .flatMap((flattedOffer) => flattedOffer.id)
      ]
    }).then(() => {
      toast.success('Produto removido da cesta com sucesso');
      queryClient.refetchQueries('basket');
      closeModal();
    });
  };

  useEffect(() => {
    setModalOffers(
      groupedOffers[selectedCategory]?.sort((a, b) =>
        (a.discount_percentage || 0) > (b.discount_percentage || 0) ? -1 : 1
      ) || []
    );
  }, [groupedOffers, selectedCategory]);

  useEffect(() => {
    setIsGrouping(true);
    setGroupedOffers(
      groupByCategory([
        ...(!!state && !!state?.baskets && !!state?.baskets?.[basketId]
          ? state?.baskets?.[basketId]?.offers_list?.map((o) => ({
              ...o,
              quantity: o.quantity_basket
            }))
          : [])
      ])
    );
    setIsGrouping(false);
  }, [state, basketId]);

  return {
    handleSelectedOffer,
    handleDeleteProduct,
    handleSelectedProduct,
    percentageSaved,
    modalOffers,
    selectedOffer,
    selectedProduct,
    isGrouping,
    handleSelectedCategory,
    selectedCategory,
    groupedOffers
  };
};
