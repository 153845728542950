import { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult
} from 'react-query';
import { api } from '../../axios/auth';
import { MutationError } from '../../../interfaces/Mutation';
import { token } from '../../queries/token';
import { useRouter } from 'next/router';

interface BasketMutateData {
  offers_list: number[];
  id: number;
}

export function useMutateBasket({
  options
}: {
  options?: UseMutationOptions<
    AxiosResponse,
    AxiosError<MutationError>,
    BasketMutateData,
    string[]
  >;
}): UseMutationResult<AxiosResponse, AxiosError, BasketMutateData, unknown> {
  const router = useRouter();
  return useMutation(
    (data: BasketMutateData) =>
      data.offers_list?.length === 0
        ? api
            .delete(`/basket/${data.id}/`, {
              headers: {
                Authorization: `Bearer ${token()}`
              }
            })
            .then((response) => {
              router.push('/');
              return response;
            })
        : api.patch(`/basket/${data.id}/`, data, {
            headers: {
              Authorization: `Bearer ${token()}`
            }
          }),
    options
  );
}
