import React from 'react';
// import { SmallSlider } from '../../../components/Slider';
import { Offer } from '../../../interfaces/Offer';
// import OfferItem from '../../../components/Offer';
import { Flex } from '../../../components/Flex';
import { Button } from '../../../components/Button';

import Icons from '../../../../public/assets/images/icons';
import { useHandleBasket } from '../../../utils/hooks/basket/useHandleBasket';
import { ItemsInBasketButton } from './Buttons/ItemsInBasketButton';
import { BasketCategoryButtons } from './Buttons/BasketCategoryButtons';
import { useRouter } from 'next/router';
interface Props {
  category: string;
  basketId: number;
  offers: Offer[];
}

const DashedLine = (): JSX.Element => (
  <svg
    width="100%"
    height="2"
    viewBox="0 0 100% 2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="0"
      y1="1"
      x2="100%"
      y2="1"
      className="stroke-current text-neutral-pure300"
      strokeWidth="1"
      strokeDasharray="8"
    />
  </svg>
);
export const OfferBasketCategory = ({
  basketId,
  category,
  offers
}: Props): JSX.Element => {
  const { handleAddMany, updatedOffers, categoryPrice, percentageSaved } =
    useHandleBasket({ offers, basketId });

  const router = useRouter();

  const [hasClicked, setHasClicked] = React.useState(false);

  return (
    <Flex
      direction="col"
      className="gap-4 overflow-hidden bg-white rounded-md py-6 relative "
      justify="between"
    >
      <Flex
        className="relative items-start lg:items-center w-full flex-col gap-4 lg:flex-row pl-6 pr-2"
        justify="between"
      >
        <Flex className="absolute right-0 top-[-25px]">
          <Icons.BakeryLeaf className="w-[60px] h-[92px]" />
        </Flex>
        <div className="absolute w-1 h-8 bg-secondary-pure left-0"></div>
        <Flex className="flex-col lg:flex-row gap-4 flex-wrap items-center ">
          <h2 className="text-[28px] 2xl:text-[32px] font-[400] text-center">
            {category.charAt(0).toUpperCase() + category.slice(1).toLowerCase()}
          </h2>
        </Flex>
      </Flex>
      <Flex direction="col" className="w-full gap-4 ">
        <Flex direction="col" className="gap-4 px-6">
          <ItemsInBasketButton
            categoryPrice={categoryPrice || 0}
            itemsInCategory={updatedOffers?.length || 0}
            percentageSaved={percentageSaved}
          />
        </Flex>
        <DashedLine />

        <BasketCategoryButtons
          offers={offers.slice(0, 10)}
          offersLength={offers.length}
          handleSelectedCategory={() => {
            router.push(`/cesta?category=${category}`);
          }}
        />
        <DashedLine />

        <Flex align="center" className="w-full flex-col lg:flex-row gap-2 px-6">
          <Button
            variant="success"
            className="w-full bg-success-dark  text-white normal-case"
            onClick={() => {
              handleAddMany();
              setHasClicked(true);
            }}
            disabled={hasClicked}
          >
            <Flex align="center" className="gap-2 font-[600]">
              <Icons.Cart className="text-white mb-[4px] " />
              Adicionar categoria ao carrinho
            </Flex>
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
