import React from 'react';
import Icons from '../../../../../public/assets/images/icons';
import { Flex } from '../../../../components/Flex';
interface Props {
  percentageSaved?: number;
  className?: string;
}
export const SavingButton = ({
  percentageSaved,
  className
}: Props): JSX.Element => {
  return (
    <Flex
      className={
        className
          ? className
          : 'gap-1 bg-success-dark rounded-3xl px-1 lg:px-2 py-1 w-fit'
      }
      align="center"
    >
      <Icons.Percentage />
      <h2 className="text-[10px] lg:text-[12px] font-[600] text-white text-center">
        Até <span> {percentageSaved?.toFixed(2)}%</span> OFF
      </h2>
    </Flex>
  );
};
