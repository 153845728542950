import React from 'react';
import { Flex } from '../../../components/Flex';
import SkeletonLoader from '../../../components/Skeleton';

export const OfferCategorySkeleton = (): JSX.Element => {
  return (
    <Flex
      direction="col"
      className="gap-8 items-center lg:items-start bg-white rounded-md p-6"
      justify="center"
    >
      <Flex
        className="w-full flex-col gap-4 lg:flex-row"
        justify="center"
        align="center"
      >
        <SkeletonLoader type="circle" width="w-[112px]" height="h-[112px]" />
      </Flex>
      <Flex
        justify="center"
        className="w-full flex-col gap-4 lg:flex-row"
        align="center"
      >
        <SkeletonLoader height="h-[60px]" width="w-[50%]" />
      </Flex>
      <Flex
        justify="center"
        className="w-full flex-col gap-4 lg:flex-row"
        align="center"
      >
        <SkeletonLoader height="h-[30px]" width="w-[70%]" />
      </Flex>
      <Flex
        justify="center"
        className="w-full flex-col gap-4 lg:flex-row"
        align="center"
      >
        <SkeletonLoader height="h-[30px]" width="w-[70%]" />
      </Flex>
    </Flex>
  );
};
