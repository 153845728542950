import { useState } from 'react';
import { Offer } from '../../../interfaces/Offer';
import {
  addOfferToMultiBasket,
  removeOfferFromMultiBasket
} from '../../../provider/Basket/action';
import { useMutateOffer } from '../../../http/mutations/Offer/useMutateOffer';
import { useModal } from '../useModal';
import { useMultiBaskets } from '../../../provider/Basket/MultiBasketsProvider';
import { useCart } from '../../../provider/Cart/CartProvider';
import { addMultipleItems } from '../../../provider/Cart/actions';
import { toast } from 'react-toastify';
import { useMutateBasket } from '../../../http/mutations/Basket/useMutateBasket';
import { useQueryClient } from 'react-query';
import { max } from 'lodash';
import { useMutateCart } from '../../../http/mutations/Cart/useMutateCart';
interface Props {
  offers?: Offer[];
  basketId: number;
}
export const useHandleBasket = ({ offers, basketId }: Props) => {
  const percentageSaved =
    Number(
      max(offers?.flatMap((offer) => offer.discount_percentage || 0)) || 0
    ) * 100;

  const categoryPrice = offers?.reduce((acc, offer) => {
    return (
      acc +
      (Number(offer.custom_price_value) || 0) * (offer.quantity_basket || 0)
    );
  }, 0);
  const { state, dispatch } = useMultiBaskets();

  const queryClient = useQueryClient();

  const { mutateAsync: mutateBasket } = useMutateBasket({});
  const { openModal, closeModal } = useModal({ modalId: 'deleteProduct' });

  const handleDeleteProduct = async (offer: Offer) => {
    if (!state.baskets[basketId]) return;
    dispatch(removeOfferFromMultiBasket({ basketId, offerId: offer.id }));
    await mutateBasket({
      id: basketId,
      offers_list: [
        ...state.baskets[basketId].offers_list
          .filter((filteredOffer) => filteredOffer.id !== offer.id)
          .flatMap((flattedOffer) => flattedOffer.id)
      ]
    }).then(() => {
      toast.success('Produto removido da cesta com sucesso');
      queryClient.refetchQueries('basket');
      closeModal();
    });
  };

  const { state: cartState, dispatch: dispatchCart } = useCart();
  const [updatedOffers, setUpdatedOffers] = useState(offers);

  const { mutateAsync } = useMutateOffer({});

  const { mutateAsync: mutateCart } = useMutateCart({});

  const handleAddMany = () => {
    if (!updatedOffers) return;
    mutateCart({
      offers: updatedOffers.map((offer) => ({
        id: offer.id,
        quantity:
          (cartState.items.find((item) => item.id === offer.id)?.quantity ||
            0) + (offer.quantity || 0) || 1
      }))
    }).then(() => {
      dispatchCart(addMultipleItems(updatedOffers));
      toast.success('Produtos adicionados ao carrinho com sucesso!');
    });
  };

  const handleAdd = async (offer: Offer, value: number | undefined) => {
    if (value === 0) {
      return openModal();
    }
    dispatch(
      addOfferToMultiBasket({
        basketId,
        offer: {
          ...offer,
          quantity_basket: value
        }
      })
    );
    setUpdatedOffers((prevOffers) => {
      return prevOffers?.map((o) => {
        if (o.id === offer.id) {
          return {
            ...o,
            quantity: value || o.quantity_basket
          };
        }
        return o;
      });
    });
    await mutateAsync({
      id: offer.id,
      quantity_basket: value
    });
  };
  return {
    handleAdd,
    handleAddMany,
    updatedOffers,
    handleDeleteProduct,
    percentageSaved,
    categoryPrice
  };
};
