import { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult
} from 'react-query';
import { api } from '../../axios/auth';
import { MutationError } from '../../../interfaces/Mutation';
import { Offer } from '../../../interfaces/Offer';
import { token } from '../../queries/token';

type OfferSubset = {
  [K in keyof Offer]?: Offer[K];
};

type OfferMutateBody = OfferSubset & { id: number };

export function useMutateOffer({
  options
}: {
  options?: UseMutationOptions<
    AxiosResponse,
    AxiosError<MutationError>,
    OfferMutateBody,
    string[]
  >;
}): UseMutationResult<AxiosResponse, AxiosError, OfferMutateBody, unknown> {
  return useMutation(
    (data: OfferMutateBody) =>
      api.patch(`/offer/${data.id}/`, data, {
        headers: {
          Authorization: `Bearer ${token()}`
        }
      }),
    options
  );
}
