import type { NextPage } from 'next';
import { Flex } from '../components/Flex';
import { Layout } from '../components/Layout';
import Icons from '../../public/assets/images/icons';
import { BasketCategories } from '../features/Baskets';
import PageLoading from '../components/PageLoading';
import { useMultiBaskets } from '../provider/Basket/MultiBasketsProvider';

const Cestas: NextPage = () => {
  const { baskets, isFetching } = useMultiBaskets();

  const basketId = !!baskets && baskets.length > 0 && baskets?.[0]?.id;

  return (
    <Layout subtitle="Suas recomendações" isHome align="start">
      <PageLoading isLoading={isFetching}>
        <Flex
          direction="col"
          className="w-full gap-8 overflow-hidden"
          align="center"
        >
          <Flex
            className="w-full h-full bg-primary-pure relative overflow-hidden"
            direction="col"
            justify="center"
            align="center"
          >
            <Flex
              className="px-[5%] 2xl:px-[2%]  w-full max-w-[1600px] text-white w-full py-12 gap-2 items-center lg:items-start"
              direction="col"
            >
              <h1 className="text-[28px] lg:text-[32px] text-center">
                Sua <span className="font-[600]">cesta semanal perfeita</span>,
                com base no que você{' '}
                <span className="font-[600]">já compra!</span>
              </h1>
              <Flex className="w-full lg:items-start lg:max-w-[60%] xl:max-w-[40%] 2xl:max-w-[35%] ">
                <h3 className="text-md lg:text-lg text-white/70">
                  Nós <span className="text-white">conhecemos</span> o seu
                  negócio. Oferecemos{' '}
                  <span className="text-white">recomendações</span> ideais
                  analisando seu histórico e agilizamos seu processo de compras
                  com economia.{' '}
                  <span className="text-white">Confira abaixo!</span>
                </h3>
              </Flex>
            </Flex>
            <Icons.LeafTopLeft className="hidden lg:block absolute top-0 right-[135px] w-[215px] h-[300px]" />
            <Icons.LeafTopRight className=" hidden lg:block  z-1 absolute top-0 right-[-90px] w-[215px] h-[300px]" />
            <Icons.LeafCircle className="hidden lg:block  z-1 absolute top-[90px] right-[118px] w-[30px] h-[30px]" />
            <Icons.LeafBottomLeft className="hidden lg:block  z-1 absolute top-[70px] right-[135px] w-[215px] h-[300px]" />
            <Icons.LeafBottomRight className="hidden lg:block  absolute top-[140px]  right-[-90px] w-[215px] h-[300px]" />
          </Flex>
          {!!basketId && (
            <Flex
              direction="col"
              className="w-full max-w-[1600px] px-[5%] 2xl:px-[2%]"
            >
              <BasketCategories basketId={basketId} />
            </Flex>
          )}
        </Flex>
      </PageLoading>
    </Layout>
  );
};

export default Cestas;
