import React from 'react';
import { Flex } from '../../../../components/Flex';
import Icons from '../../../../../public/assets/images/icons';
import { formatCurrency } from '../../../../utils/helpers/formatters';
import { SavingButton } from './SavingButton';
interface Props {
  itemsInCategory: number;
  categoryPrice: number;
  percentageSaved?: number;
}
export const ItemsInBasketButton = ({
  itemsInCategory,
  categoryPrice,
  percentageSaved
}: Props): JSX.Element => {
  return (
    <Flex className=" w-full flex-col  gap-2 ">
      <p className="font-[600] text-neutral-pure1000">Total da categoria</p>
      <Flex className="w-full gap-2 2xl:gap-0 items-center" justify="between">
        <Flex className="gap-3 font-[600] text-primary-pure " align="center">
          <Flex className="relative">
            <Icons.Cart className="text-primary-pure mb-[4px] " />
            <Flex className="left-3 font-[400] absolute rounded-[100%] bg-primary-pure text-white px-1 text-[12px]">
              {itemsInCategory}
            </Flex>
          </Flex>
          <p className="text-[20px] 2xl:text-[26px]">
            {formatCurrency(categoryPrice)}
          </p>
        </Flex>
        {!!percentageSaved && (
          <SavingButton percentageSaved={percentageSaved} />
        )}
      </Flex>
    </Flex>
  );
};
