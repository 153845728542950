import { EmptyList } from '../../../components/EmptyList';

export const EmptyBasket = () => {
  return (
    <EmptyList
      buttonLabel="Ver cestas"
      title="Você não possui ofertas para essa cesta :("
    >
      <p className="text-sm text-neutral-600 ">
        Mas não se preocupe! Você pode conferir outras cestas.
      </p>
    </EmptyList>
  );
};
